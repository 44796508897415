$navheight : 130px;

.addtocardmodal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: none;

    h1 {
        width: 100%;
        text-align: center;
    }

    &.visible {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__buttonwrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: 24rem;
    }

    &__btn {
        max-width: 24rem;
        margin-top: 10px;
        @include mediaquery("md", smaller) {
            max-width: 100%
        }
    }

    &__close {

    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &__content {
        background-color: rgba(255, 255, 255, 255);
        display: block;
        padding: 30px;
        margin: 0 20px #{$navheight};
        height: auto;
        max-height: 80%;
        //max-width: 95%;
        max-width: 600px;
        z-index: 1;
        position: relative;

        @include mediaquery("md", smaller) {
            padding: 15px
        }
    }

    &__infowrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: 60%;
        flex-grow: 1;
        min-width: auto;
        @include mediaquery("md", smaller) {
            justify-content: center;
            align-items: center;
        }
    }

    &__imagewrapper {
        display: block;
        max-width: 24rem;
        padding-bottom: 2rem;
        @include mediaquery("md", smaller) {
            max-height: 16rem;
        }
    }

    &__textwrapper {
        display: block;
        padding-left: 2rem;
        padding-bottom: 2rem;
        max-width: 55%;
        @include mediaquery("md", smaller) {
            padding-right: 2rem;
            max-width: 100%
        }
    }

}
