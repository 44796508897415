$component: '.newsletter';

#{$component} {
    @include big-margin-bottom;
    &__hl {
        margin-bottom: $baseline * 2;
    }
    &__text {
        margin-bottom: $baseline !important;
    }
    &__input {
        margin-bottom: $grid-width;
    }
}
