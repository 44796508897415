.header__navigation {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: 0.2s ease-in;

    @include mediaquery('md') {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }

    @include mediaquery('md', 'lt') {
        max-height: 0;
        overflow: hidden;
    }

    .only-mobile {
        @include mediaquery('lg') {
            display: none !important;
        }
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: 0.2s ease-in;
    }

    &.visible {
        opacity: 1;
        overflow: visible;
        visibility: visible;
        transform: translateY(0);

        .menu__list--level-0 {
            height: calc(100vh - 80px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;

            @include mediaquery('lg') {
                height: auto !important;
                overflow-y: visible;
            }
        }
    }

    .menu {
        &__list {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            list-style: none;

            &--childs {
                text-align: left;
            }

            @include mediaquery('lg') {
                flex-direction: row;
                align-items: flex-start;
            }
        }

        &__item {
            flex: 0 1 auto;
            color: color('background');
            color: var(--color-background);
            text-transform: uppercase;
            position: relative;

            &.open {
                & > .menu__link > .menu__icon {
                    transform: scaleY(-1);
                }

                & > .menu__list--childs {
                    opacity: 1;
                    max-height: 1000px;
                    visibility: visible;
                    transition: max-height 1s ease-in-out;
                }
            }

            &--has-childs {
                & > .menu__link > .menu__icon {
                    display: block;
                }
            }

            @include mediaquery('lg') {
                &.menu__item--parent {
                    flex: 0 1 280px;
                    flex-basis: 280px;
                }

                // &.open .menu__list--childs {
                //     opacity: 0;
                //     visibility: hidden;
                // }

                &:hover > .menu__list--childs.menu__list--level-1 {
                    transition: max-height 1s ease-in-out;
                    opacity: 1 !important;
                    max-height: 1000px;
                    visibility: visible !important;
                    transform: translateY(0) !important;
                }

                &.open:not(:hover) > .menu__list--childs.menu__list--level-1 {
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;
                }
            }
        }

        &__link {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: relative;
            text-decoration: none;
            color: color('background');
            color: var(--color-background);
            padding: $grid-width;
            padding-right: 40px;
            text-align: left;
            letter-spacing: 0.055em;
            cursor: pointer;

            @include mediaquery('lg') {
                text-align: center;
            }

            &__text {
                flex: 1;
            }
        }

        &__list--level-0 {
            background-color: rgba(0, 0, 0, 1);
            height: 0;

            @include mediaquery('lg') {
                background-color: rgba(0, 0, 0, 0.85);
                height: auto;
            }

            & > .menu__item > .menu__link {
                @include mediaquery('lg', "only") {
                    white-space: nowrap;
                }
            }
        }

        &__list--level-1 > .menu__item > .menu__link {
            background-color: rgba(0, 0, 0, 1);
            text-transform: uppercase;

            @include mediaquery('md', 'smaller') {
                background-color: rgb(51, 51, 51);
                & > .menu__link__text {
                    padding-left: 1.5rem;
                }
            }
        }

        &__list--level-2 > .menu__item > .menu__link {
            background-color: rgba(60, 60, 60, 1);

            @include mediaquery('md', 'smaller') {
                background-color: rgb(76, 76, 76);
                & > .menu__link__text {
                    padding-left: 3rem;
                }
            }

            @include mediaquery('lg') {
                background-color: rgba(60, 60, 60, 0.9);
            }
        }

        &__list--level-3 > .menu__item > .menu__link {
            background-color: rgba(153, 153, 153, 1);
            padding-left: 3rem;

            @include mediaquery('md', 'smaller') {
                background-color: rgb(105, 103, 104);
                & > .menu__link__text {
                    padding-left: 4.5rem;
                }
            }

            @include mediaquery('lg') {
                background-color: rgba(153, 153, 153, 0.9);
            }
        }

        &__icon {
            display: none;
            position: absolute;
            top: 14px;
            right: 14px;
            width: 22px;
            height: 22px;
            transition: 0.2s ease-in;
        }

        &__list--childs {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), transform 0.2s;

            @include mediaquery('lg') {
                flex-direction: column;
                align-items: stretch;
                opacity: 0;
                visibility: hidden;
                width: 100%;
                transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
                max-height: 0;
            }

            .menu__item {
                color: color('main');
                color: var(--color-main);
                text-transform: none;
            }

            .menu__link {
                background-color: color('main');
                background-color: var(--color-main);
                color: color('background');
                color: var(--color-background);
                text-align: left;
            }

            &.menu__list--level-1 {
                @include mediaquery('lg') {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    transform: translateY(25px);
                }

                & > .menu__item {
                    margin-bottom: 2px;
                }
            }
        }
    }
}

// Hamburger
.header__hamburger {
    grid-area: hamburger;
    width: 30px;
    height: 30px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @include mediaquery('lg') {
        display: none;
    }

    span {
        width: 100%;
        display: block;
        position: absolute;
        height: 3px;
        background-color: color('background');
        background-color: var(--color-background);
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        opacity: 1;

        &:nth-child(1) {
            top: 6px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 14px;
        }
        &:nth-child(4) {
            top: 22px;
        }
    }

    &.active {
        span {
            &:nth-child(1),
            &:nth-child(4) {
                top: 10px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}
