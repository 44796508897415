.contenthead {
    margin-top: -$baseline;

    &__wrapper {
        padding: 0;

        .contentteaser {
        }
    }
}
