$component: '.saleshub-reduced';

#{$component} {
    @include general-slider;

    .saleshub__item__overviewlink {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        background-color: var(--color-primary);
    }

}