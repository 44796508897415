$component: '.jobshub-layout-two';

#{$component} {
  background-color: #ffffff;

  .jobshub__results__list__itemheader__layout__two {
    color: var(--color-primary);
    text-align: left;
    &:not(:first-child) {
      .hl {
        margin-top: 10px;
        padding-top: 18px;
        border-top: 1px solid var(--color-grey-medium-lighten);
      }
    }

    .hl {
      margin-bottom: 14px;
    }
  }

  .jobshub__results__list__item__layout__two {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;


    svg {
      fill: var(--color-grey-dark);
    }

    &.last-grid-cell-in-row {
      justify-content: space-between;
      flex-wrap: nowrap;

      span {
        display: flex;
        align-items: center;
        justify-self: left;
        flex-wrap: wrap;
        white-space: normal;
      }

      .job-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: right;

        svg {
          right: -10px;


          fill: var(--color-primary);
          stroke: var(--color-primary);

          &:hover {
            stroke: var(--color-grey-dark);
          }
        }
      }
    }
  }
}
