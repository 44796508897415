$searchAlertConfig: '.search-alert-config';

#{$searchAlertConfig} {

  &__input {
    margin-right: 15px;
  }

  &__input-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__input-button-save {
    margin-right: 15px;
  }

  &__collapse-container {
    margin-bottom: 10px;
  }

  &__search-result {
    margin-top: 30px;
  }

  &__search-result-headline {
    margin-bottom: 10px;
  }

  &__action-form {
    width: 100%;
    margin-bottom: 30px;
  }

  &__form-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
  }
}


$searchAlertConfig: '.search-alert-banner';

#{$searchAlertConfig} {

  &__selected-alert-banner {
    margin-bottom: 10px;
  }

  &__selected-alert-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &__selected-alert-title {
    margin-right: 15px;
  }

}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}