$component: '.santander-widget';

#santander-widget-container {
  #santander-widget {
    display: flex !important;
    position: relative;
    box-shadow: none;
    background: none;
    transform: none;
    left: 0%;
    bottom: 0px !important;
    height: 100%;
    margin: 0 0 1.5rem 0;
    z-index: unset;
  }
}

#san-float-widget-btn {
  width: 100%;
  padding: 0px;
}

#{$component} {
  &__button-parent-div {
    width: 100%;
  }

  &__financing-button_hidden {
    display: none !important;
  }
}
