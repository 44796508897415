$component: '.productdetail';

body.contains-component-productdetail {
  @include mediaquery("md", smaller) {
    .muuuh-whatsapp-trigger-btn, #jumpToService {
      display: none !important;
    }
  }
}

#{$component} {
  @include big-margin-bottom;
  position: relative;

  &__header-link {
    @extend .text-small;
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
    padding-bottom: 0.4em;
    border-bottom: solid 1px;

    &:hover {
      color: color('beresa-red');
      color: var(--color-red-light);
    }

    > .icon {
      vertical-align: middle;
      width: $grid-width;
      height: $grid-width;
      margin: 0 -0.4rem;
    }

    > span {
      vertical-align: middle;
    }
  }

  &__icons {
    flex-shrink: 0;

    form,
    svg {
      cursor: pointer;
    }
  }

  &__detail-row {
    position: relative;
    @include mediaquery('sm') {
      flex-direction: column-reverse;
    }
    @include mediaquery('lg') {
      // min-height: 43rem;
      //min-height: calc(16.517rem + 34.48276vw);
      flex-direction: unset;
    }
    @include mediaquery('x4l') {
      //min-height: calc(16.517rem + 34.48276vw);
    }
  }

  &__left-col {
    @include mediaquery('lg') {
      //width: 66.666%;
      box-sizing: border-box;
      //padding: 0 $grid-width;
    }
  }

  &__key-facts {
    justify-content: center;

    > * {
      margin: 0 $grid-width;
    }
  }

  &__tertiary-btns {
    font-weight: 400;
    text-transform: none;
  }

  &__pricing {
    display: flex;
    flex-wrap: wrap;
    margin: 0 #{-$grid-width};


    > * {
      flex-basis: 210px;
      flex-grow: 1;
      margin-bottom: $grid-width;
      padding: 0 $grid-width;

      @include mediaquery("md", "lte") {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &__sold {
    margin-top: 3 * $grid-width;

    a {
      color: inherit;
    }
  }

  &__tab-row {
    margin-bottom: -$grid-width;
  }

  &__tab-col {
    position: relative;
    max-width: 80rem;
    margin-bottom: $grid-width;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      @include mediaquery('lg') {
        border-left: solid 1px color('ui-soft');
        border-left-color: var(--color-ui-soft);
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  &__tab-dl {
    > dt {
      max-width: 50%;
    }

    dt.mod--state {
      width: 10rem;
    }

    dd.mod--state {
      float: left;
      width: calc(100% - 11.5rem);
      text-align: right;
    }
  }

  &__certificate {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &__text-col {
    @include mediaquery('lg') {
      column-count: 3;
      column-gap: $grid-width * 2;
      column-rule: 1px solid color('ui-soft');
      column-rule: 1px solid var(--color-ui-soft);
    }
  }

  &__text-list {
    margin: 0;
    padding: 0 0 0 2rem;

    > li {
      margin: 0;
      padding: 0;
    }
  }

  &__pricing-spacer {
    @include mediaquery('x4l') {
      min-height: 7.2rem;
    }
  }

  &__pricing-spacer-car-package {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__infotabs.hideleasing .data-leasing-tabs-title {
    display: none;
  }

  &__empty-placeholder {
    height: 125px;
  }
}
