.carslider {
    & *:focus {
        outline: none;
        outline: 0;
    }
    & > *::-moz-focus-inner {
        border: 0;
    }

    &__wrapper,
    &__images-wrapper,
    &__thumbnails-wrapper {
        position: relative;
    }

    &__control {
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: calc(50% - 16px);

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &[aria-disabled='true'] {
            opacity: 0;
            cursor: auto;
        }

        svg {
            display: block;
            stroke: #ffffff;
        }
    }

    &__images {
        cursor: move;

        .s-car-card & {
            cursor: auto;
        }
    }

    &__image {
        overflow: hidden;
        position: relative;

        &--360 {
            cursor: pointer;

            .threesixtyoverlay {
                width: 100% !important;
            }
        }

        a {
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__dots {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: #{$grid-width} 0 0 0;
        padding: 0;
        align-items: center;
        justify-content: center;
    }

    &__dot {
        width: 14px;
        height: 14px;
        border: 2px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        border-radius: 50%;

        &:not(:first-child) {
            margin-left: #{$grid-width};
        }

        &--active {
            border-color: black;
            background-color: black;
        }
    }

    &__thumbnails-wrapper {
        display: block;
        overflow: hidden;
        min-height: auto;

        @include mediaquery('lg') {
            min-height: calc(112px + 1.5rem);
        }
    }

    &__thumbnails,
    &__thumbnails__fake {
        margin: #{$grid-width} 0 0 0;
        padding: 0 0 #{$grid-width} 0;
        list-style: none;
        display: flex;
    }

    &__thumbnails__fake {
        .carslider__thumbnail__fake {
            width: calc((100% - 40px) / 3);
            margin: 0;
            visibility: hidden;
            cursor: default;
            position: relative;
            overflow: hidden;

            img,
            img.tns-complete {
                display: block;
                width: 100%;
                height: auto;
                opacity: 0.5;
                transition: 0.25s;
            }

            &:hover,
            &.tns-nav-active {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &__thumbnail {
        a {
            display: block;
            position: relative;
        }

        margin: 0;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        min-height: 97px;
        object-fit: contain;

        @include mediaquery('lg') {
            min-height: 72px;
        }

        img,
        img.tns-complete {
            display: block;
            width: 100%;
            height: auto;
            opacity: 0.5;
            transition: 0.25s;
            font-size: 12px;
            line-height: normal;
        }

        &:hover,
        &.tns-nav-active {
            img {
                opacity: 1;
            }
        }

        &--360 .threesixtyoverlay .paragraph {
            font-size: 1.4rem;
            text-align: center;

            @include mediaquery('lg') {
                font-size: 1.6rem;
            }
        }
    }

    .threesixtyoverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 20px);
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.25s;

        // &:hover {
        //     transform: scale(1.2);
        // }
    }

    .threesixtyoverlay-wrapper {
        .threesixtyoverlay {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__thumbnail {
            width: 100%;
        }
        &__big-image {
            width: 100%;
        }
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
    }
}
