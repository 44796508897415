.googlemap {
    position: relative !important;
    height: 100%;
    width: 100%;
    display: block;
    overflow: visible !important;

    .gm-style-iw {
        padding: 2rem;
        border-radius: 0;
        box-shadow: none;
    }

    &__infowindow {
        &__headline {
            margin-top: 0 !important;
        }

        &__address {
        }

        &__phone {
            text-decoration: none;
            color: inherit;
        }

        &__link {
            margin-bottom: 0;
        }
    }
}
