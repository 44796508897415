// THIS IS MESSY!!

// Add letter spacing again...
button,
.button,
h1,
h2,
h3,
h4,
h5,
h6,
.s-car-card__hl,
.s-car-card.mod--slim .s-car-card__hl,
.hl,
.hl--body {
    letter-spacing: 0.055em;
}

figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

// IOS User Agent Styles Overrides

.input
.input__input {
    border-radius: 0;
}

// Really important stuff
// Do not delete this. Put it in buikit.
.hero {

    &.no-content,
    &.only-image {
        .hero__image {
            position: relative !important;
            @include mediaquery("md", "lt") {
                position: relative !important;
            }
        }
    }

    margin-top: -$baseline;

    .hl {
        padding-top: $baseline;
    }

    max-width: 100%;

    img {
        min-width: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: calc(100% - 3rem);
        color: color('background');
        color: var(--color-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include mediaquery("md", "lt") {

        &__wrapper {
            display: flex;
            justify-content: center;
        }

        &__image {
            height: 100%;
            min-width: 100%;
            position: absolute;

            picture {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-content: center;
                img {
                    display: block;
                    min-height: 100%;
                    max-width: none;
                }
            }
        }

        &__content {
            position: relative;
            top: 0;
            left: 0;
            height: 100%;
            display: inline-flex;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-pack: center;
            justify-content: center
        }
    }
}
