.contentimage {
    position: relative;

    &__link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__figure {
        margin: 0;
        padding: 0;
        position: relative;
        @include mediaquery('xl', smaller) {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-content: center;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.image-with-content {
            @include mediaquery('xl', smaller) {
                position: absolute;
            }
        }

        &.image-without-content {
            //width: 100%;
            //height: auto;
            //width: auto;
            height: 100%;
            //min-height: 300px;
        }

    }

    &--fullsize {
    }

    &__text {
        box-sizing: border-box;
        position: absolute;
        @include mediaquery('xl', smaller) {
            position: relative;
        }

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 6rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--notext {
            position: relative;
            top: auto;
            left: auto;
            display: block;
            padding: 0;

            .contentimage__button {
                width: 100%;
            }
        }
    }

    &__headline {
        color: color('background');
        color: var(--color-background);
        text-align: center;
        text-decoration: none;
    }
}
