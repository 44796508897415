body {
  font-family: 'Beresa Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  line-height: 2.6rem;
  @extend .text-body;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: color(main-text);
  color: var(--color-main-text);
  background: color(background);
  padding: 13.2rem 0 0;

  @include mediaquery("sm", "only") {
    padding-top: 128px;
  }

  @include mediaquery("md", "only") {
    padding-top: 133px;
  }
}


.main-content {
  overflow-x: hidden;
  padding-top: $baseline;
}


a {
  color: color('beresa-red');
  color: var(--color-primary);
}

svg.car-icon,
.s-car-card__key-fact .icon,
.infobox__icon {
  color: color(main-icon) !important;
  fill: color(main-icon) !important;
  color: var(--color-main-icon) !important;
  fill: var(--color-main-icon) !important;
}

.br-lte-md {
  @include mediaquery('md', 'lte') {
    display: block;
  }
}

.header__grid {
  @media only screen and (max-width: 399px) {
    grid-template-columns: 1rem 3rem 1.5rem 10.7rem 1fr 2rem !important;
  }
}

[data-bookmark] {
  cursor: pointer;
  fill: none;

  .s-car-card__fav {
    stroke: var(--color-main-icon) !important;
  }

  &[data-bookmark-bookmarked='true'] {
    .s-car-card__fav {
      stroke: var(--color-main-icon) !important;
      fill: var(--color-main-icon) !important;
    }
  }
}

.s-car-card__variant-detail-row {
  justify-content: inherit;
}

.s-car-card__variant-detail-row-column {
  width: 50%;
}

.s-car-card__facts {
  font-weight: 400;
}

.jump-to-page {

  &_container {
    height: 56px;
    width: 56px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 80px;
    right: 10px;
    margin: 15px;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-width: 1;
    border-left-color: rgb(255, 255, 255);
    border-left-style: solid;
    border-left-width: 2px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 2px;
    border-top-color: rgb(255, 255, 255);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-top-style: solid;
    border-top-width: 2px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    opacity: 0.85;
    cursor: pointer;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
    font-family: Arial, serif;
    font-size: 13.33333px;
    font-stretch: 100%;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    z-index: 1000;

    @include mediaquery('md', 'lte') {
      right: 2px;
    }

    &:hover {
      opacity: 1;
      filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.4));
    }
  }

  &_icon {
    height: 34.15px;
    width: 34.25px;
  }
}

// santander widget should be invisible on every page except product detail page
.san-widget-floating {
  display: none !important;
}

// whatsapp fap button sizing
.muuuh-whatsapp-trigger-btn {
  width: 56px !important;
  height: 56px !important;

  svg {
    width: 43px !important;
    height: 43px !important;
  }
}