.cockpit__greeter {

  h2 {
    margin-top: 0;
  }

  .paragraph:first-child {
      display: none;
    }
}
