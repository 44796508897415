$navheight: 130px;

.alerts__list__item .tag {
  padding: 0px;
  min-height: auto;
  cursor: default;
}

.cockpit__myalerts__buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.alerts__list__item__tooltip-container .tag {
  padding: 0px;
  min-height: auto;
  color: white;
}

.alerts__list__item__tooltip-container:hover .alerts__list__item__tooltip-item {
  visibility: visible;
}

.alerts__list__item__facets-make-model .productsearch-result__tag-bar {
  display: inline;
}

.alerts__list__item__row {
  width: 100%;
}

.alerts__list__item {
  &__tooltip-container {
    position: relative;
    display: inline-block;
    max-width: 500px;
  }

  &__exact-match-container {
    position: relative;
    display: flex;
    max-width: 500px;
  }

  &__info {
    position: relative;
    left: 5px;
    top: 4px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  &__tooltip-item-container {
    padding: 10px;
    visibility: hidden;
    width: 500px;
    z-index: 1;
  }

  &__tooltip-item {
    background-color: var(--color-primary);
    color: white;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    padding: 12px;

    /* Position the tooltip text - see examples below! */
    position: relative;

    @include mediaquery("lg", smaller) {
      max-width: 70vw;
    }
  }

  &__facets-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.productsearch-result__tag {
  margin: 0px;
}

.cockpit-alerts {
  &__item-button {
    margin-bottom: 5px;
    width: 100%;
  }
}

.deletemodal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: none;

  h1 {
    width: 100%;
    text-align: center;
  }

  &.visible {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttonwrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 24rem;
  }

  &__btn {
    max-width: 24rem;
    margin-top: 10px;
    @include mediaquery("md", smaller) {
      max-width: 100%
    }
  }

  &__close {

  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    background-color: rgba(255, 255, 255, 255);
    display: block;
    padding: 30px;
    margin: 0 20px #{$navheight};
    height: auto;
    max-height: 80%;
    //max-width: 80%;
    max-width: 600px;
    //width: 30%;
    z-index: 1;
    position: relative;
    @include mediaquery("md", smaller) {
      padding: 15px
    }
  }

  &__infowrapper,
  &__namewrapper,
  &__textwrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 60%;
    flex-grow: 1;
    min-width: auto;
    @include mediaquery("md", smaller) {
      justify-content: center;
      align-items: center;
    }
  }

}