$component: '.header';
$header-spacing-sm: 1.5rem;
$header-spacing-xl: 6rem;

#{$component} {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.2rem;
  line-height: 2.2rem;

  @include mediaquery("sm", "only") {
    background-color: var(--color-black);
    height: 128px;
  }

  @include mediaquery("md", "only") {
    background-color: var(--color-black);
    height: 133px;
  }

  &__wrapper {
    padding: 0;
  }

  &__grid {
    -ms-flex-align: center;
    align-items: center;
    min-height: $bar-height;
    background-color: color('main');
    background-color: var(--color-black);

    display: flex;
    flex-direction: row;
    padding-left: $header-spacing-sm;
    padding-right: $header-spacing-sm;

    & > * {
      align-self: center;
    }

    @supports (display: grid) {
      padding-left: 0;
      padding-right: 0;

      display: grid;
      grid-template-columns: $header-spacing-sm 3rem $header-spacing-sm 14.7rem 1fr $header-spacing-sm;
      grid-template-areas: '. hamburger . logo links .' 'searchbar searchbar searchbar searchbar searchbar searchbar';
    }


    @include mediaquery("sm", "only") {
      grid-template-columns: $header-spacing-sm 3rem $header-spacing-sm 12.7rem 1fr $header-spacing-sm;
    }

    @include mediaquery('lg') {
      grid-template-areas: '. logo searchbar links .';
      grid-template-columns: $header-spacing-sm 14.7rem 1fr 14.7rem $header-spacing-sm;
    }

    @include mediaquery(xl) {
      padding-left: $header-spacing-xl;
      padding-right: $header-spacing-xl;

      @supports (display: grid) {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: $header-spacing-xl 17.7rem 1fr 17.7rem $header-spacing-xl;
        grid-template-areas: '. logo searchbar links .';
      }
    }
  }

  &__logo {
    padding: 0 $header-spacing-sm;
    box-sizing: border-box;
    width: 100%;
    flex: 0 1 130px;
    order: 1;
    padding: $header-spacing-sm 0;

    @include mediaquery(xl) {
      width: 110%;
      padding: 0 $header-spacing-xl 0 0;
    }

    @supports (display: grid) {
      grid-area: logo;
    }
  }

  &__searchbar {
    flex: 1;
    order: 2;

    @supports (display: grid) {
      grid-area: logo;
      grid-area: searchbar;
    }
  }

  &__menu {
    flex: 0 1 auto;
    order: 3;
    padding: 0;
    padding-left: $header-spacing-xl;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    list-style: none;
    min-height: $bar-height;

    @supports (display: grid) {
      padding-left: 0;
      grid-area: links;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-right: -2.5rem;
  }

  &__bubble-wrapper {
    position: relative;
  }

  &__bubble {
    position: absolute;
    left: calc(100% - 7px);
    bottom: calc(100% - 7px);
    background: color('beresa-red');
    color: color('background');

    display: flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle;

    border-radius: 0.9rem;
    min-width: 1.8rem;
    height: 1.8rem;
    box-sizing: border-box;
    padding: 0 4px;
  }

  &__bubble-text {
    text-align: end;
    font-size: 1.4rem;
    display: block;
    margin: -1.5px 0px;
    width: 100%;
    font-weight: 700;
    line-height: 2rem;
  }

  .header__button__user {
    .header__bubble {
      display: none;
    }
  }

}
