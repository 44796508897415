body.contains-component-productdetail,
body.contains-component-equipmentdetail {

  .section.contentteaser,
  .section.infobox,
  .section.productstream {
    margin: 0 auto 4rem auto;
    max-width: 144rem;
    padding: 0 3rem;

    @include mediaquery(xl) {
      padding: 0 6rem;
    }
  }

  .section.contentteaser {
    padding: 0 0;

    .contentslider__control svg {
      display: block;
      stroke: #ffffff;
    }
  }

  .section.paymentwrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .section.productstream {
    & > * {
      margin: 0 -1.5rem;
    }
  }
}

body {
  .contenthead__wrapper .normal-content-behaviour {
    padding: 0 1.5rem;

    .saleshub__item__card,
    .jobshub__item__card {
      @include mediaquery(md, smaller) {
        margin: 1.5rem 0.75rem !important;
      }
    }

    @include mediaquery(xl) {
      padding: 0 6rem !important;
    }

    @include mediaquery(x4l, only) {
      padding: 0 0 !important;
    }
  }
}

