$component: '.newshub-reduced';

#{$component} {

    @include general-slider;

    user-select: none;

    .newshub__item__teaser {
        user-select: none;
    }

    //.newshub__item__card__fake {
    //    position: relative;
    //    display: block;
    //    background-color: var(--color-primary);
    //    width: 100%;
    //    height: 100%;
    //}

    .newshub__item__card__overviewlink {
        position: relative;
        display: block;
        background-color: var(--color-primary);
        width: 100%;
        height: 100%;
    }
}