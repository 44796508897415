.margin-bottom-1 {
    margin-bottom: $grid-width !important;
}

.margin-bottom-2 {
    margin-bottom: $grid-width * 2 !important;
}
.margin-bottom-3 {
    margin-bottom: $grid-width * 3 !important;
}

@mixin big-margin-bottom {
    margin-bottom: $baseline * 5;
}
