$componentFacet: '.next-best-actions-result';

#{$componentFacet} {
  &__card {
    margin-bottom: $grid-width;
  }

  .carslider {
    &__thumbnails-wrapper {
      display: none;
    }
  }
}
