.countup {
    text-align: center;

    h1,
    h2,
    h3 {
        line-height: 1.15;
    }

    &__target {
        color: var(--color-primary);
    }
}
