$component: '.header .global-search-bar';

#{$component} {
    align-items: flex-start;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &__searchsearch-page-text-input {
        width: 100%;
    }

    .search-bar__button,
    .input__input {
        margin: 0;
    }

    @include mediaquery('sm', 'only') {
        padding-left: 0;
        padding-right: 0;
    }

    @include mediaquery('lg', 'lte') {
        background-color: #000;
    }

    &__result {
        left: 0;
        top: 100%;
        width: 100%;
        margin: 0;
        padding: 0;

        ul {
            padding: 0;
            list-style: none;
        }

        li {
            margin: 0;
            padding: 0;
        }

        a {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            padding: 1.3rem 2.5rem;
            color: color('main');
            color: var(--color-main);
            text-decoration: none;

            @include mediaquery('md', 'only') {
                flex-direction: row;
            }

            &:focus,
            &:hover {
                position: relative;
                z-index: 1;
                color: color('background');
                color: var(--color-background);
                fill: color('background');
                fill: var(--color-background);
                background-color: color('ui-strong');
                background-color: var(--color-ui-strong);
                outline: 0;

                @include mediaquery('lg', 'lte') {
                    background-color: color('background');
                    background-color: var(--color-background);
                    color: color('main');
                    color: var(--color-main);
                    fill: color('main');
                    fill: var(--color-main);
                }
            }

            img {
                margin-right: 1.3rem;

                &[src=''] {
                    display: none;
                }

                @include mediaquery('sm', 'only') {
                    display: none;
                }
            }
        }
    }

    &__keyword-banner-result .result > a {
        margin-bottom: 15px;
        padding: 1.3rem 2.5rem;
    }

    &__keyword-banner-result .result > a > div > p {
        margin: 0;
    }

    &__keyword-banner-result .result > a > img {
        width: 15rem;
        margin-right: 1.3rem;

        &[src=''] {
            display: none;
        }

        @include mediaquery('md', 'lte') {
            min-height: auto;
            max-width: 13rem;
        }

        @include mediaquery('sm', 'only') {
            display: none;
        }
    }

    > .search-bar__input {
        position: relative;
    }

    > .search-input {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        flex-grow: 2;
    }

    &__result-container {
        background-color: color('background');
        background-color: var(--color-background);
        overflow: auto;
        position: absolute;
        width: 100%;
        margin-top: 45px;
        padding-right: 15px;
        z-index: 2;
        display: none;
        max-height: 85vh;
        max-width: 100%;
        box-sizing: border-box;

        @include mediaquery('sm', only) {
            margin-top: 52px;
            width: 100vw;
        }

        @include mediaquery('md', only) {
            overflow-x: hidden;
            margin-top: 48px;
            width: 100vw;
            position: fixed;
            left: 0px;

            .global-search-bar__result-box {
                padding-left: 15px;
                padding-right: 0px;
            }
        }
    }

    &__search-term-placeholder-container {
        display: flex;
        flex-direction: row;
    }

    &__search-page-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom: 1px solid var(--color-ui-strong);
        padding-bottom: 10px;
    }

    &__template {
        display: none;
    }

    &__icon {
        width: 22px;
        height: 22px;
        margin-right: 0.8rem;
    }

    &__item {
        display: flex;
    }

    &__keyword-banner-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background-color: color('ui-spot');
        background-color: var(--color-primary);
        color: color('background') !important;
        color: var(--color-background) !important;
    }

    &__item-data-container {
        display: flex;
        width: 100%;
    }

    &__item-fact {
        display: flex;
        align-items: center;
        width: 30%;
        flex-grow: 1;
    }

    &__result-box {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        width: 100%;
        box-sizing: border-box;
    }

    &__keyword-banner-result-box {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
    }

    &__search-page-text {
        display: none;
    }

    &__value-list-container {
        display: none;
    }

    span.netprice-info {
        b {
            font-weight: normal;
        }

        @include mediaquery('md', 'lte') {
            display: block;
        }
    }

    &__result-box {
        img {
            max-width: 15rem;
            min-height: 8rem;

            &[src=''] {
                display: none;
            }

            @include mediaquery('md', 'lte') {
                min-height: auto;
                max-width: 13rem;
            }

            @include mediaquery('sm', 'only') {
                display: none;
            }
        }

        &__car-fact {
            display: flex;
            align-items: center;
        }

        .global-search-bar {
            &__item-data-container {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                min-height: 8rem;

                @include mediaquery('md', 'lte') {
                    min-height: auto;
                    padding-left: 0rem;
                }
            }

            &__car-fact {
                display: none;

                @include mediaquery('lg', gte) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    span,
                    svg {
                        display: block;
                    }
                }
            }

            &__car-fact__text {
                display: inline-block;
            }
        }
    }
}

$searchresultpage: '.contains-component-maybe-search-result';
#{$searchresultpage} {
    .main-content {
        .global-search-bar {
            &__search-page-text {
                display: none;
            }

            &__car-fact {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 5px;
                padding-bottom: 5px;

                span,
                svg {
                    display: block;
                }
            }

            &__car-fact__text {
                display: inline-block;
            }

            &__keyword-banner-result .result a {
                margin-bottom: 15px;
            }

            &__keyword-banner-result-box {
                padding: 0;
            }

            &__keyword-banner-item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 1.3rem 2.5rem !important;
                background-color: color('ui-spot');
                background-color: var(--color-primary);
                color: color('background') !important;
                color: var(--color-background) !important;

                img {
                    margin-right: 1.3rem;

                    @include mediaquery('md', 'lte') {
                        min-height: auto;
                        max-width: 13rem;
                    }

                    @include mediaquery('sm', 'only') {
                        display: none;
                    }
                }

                p {
                    margin: 0;
                }
            }

            &__icon {
                width: 22px;
                height: 22px;
                margin-right: 0.8rem;
            }

            &__value-list-container {
                display: none;
            }

            &__template {
                display: none;
            }

            &__search-term-placeholder-container {
                display: flex;
                flex-direction: row;
            }

            &__search-page-links {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                border-bottom: 1px solid var(--color-ui-strong);
                padding-bottom: 10px;
            }

            &__result {
                left: 0;
                top: 100%;
                width: 100%;
                margin: 0;
                padding: 0;

                ul {
                    list-style-type: none;
                    padding: 0;
                }

                li {
                    margin: 0;
                    padding: 0;
                }

                a {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: row;
                    padding: 1.3rem 2.5rem;
                    color: color('main');
                    color: var(--color-main);
                    text-decoration: none;

                    @include mediaquery('md', 'only') {
                        flex-direction: row;
                    }

                    &:focus,
                    &:hover {
                        position: relative;
                        z-index: 1;
                        color: color('background');
                        color: var(--color-background);
                        fill: color('background');
                        fill: var(--color-background);
                        background-color: color('ui-strong');
                        background-color: var(--color-ui-strong);
                        outline: 0;

                        @include mediaquery('lg', 'lte') {
                            background-color: color('background');
                            background-color: var(--color-background);
                            color: color('main');
                            color: var(--color-main);
                            fill: color('main');
                            fill: var(--color-main);
                        }
                    }

                    img {
                        margin-right: 1.3rem;

                        &[src=''] {
                            display: none;
                        }

                        @include mediaquery('md', 'lte') {
                            min-height: auto;
                            max-width: 13rem;
                        }

                        @include mediaquery('sm', 'only') {
                            display: none;
                        }
                    }
                }
            }
        }

        @include mediaquery('lg', smaller) {
            .s-contact-us {
                margin: 0;
            }

            .global-search-bar {
                align-items: flex-start;
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                &__result-container {
                    background-color: color('background');
                    background-color: var(--color-background);
                    position: relative;
                    width: 100%;
                    display: block;
                }

                @include mediaquery('sm', 'only') {
                    padding-left: 0;
                    padding-right: 0;
                }

                &__search-page-links {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    border-bottom: 1px solid var(--color-ui-strong);
                    padding-bottom: 10px;
                }

                &__icon {
                    width: 22px;
                    height: 22px;
                    margin-right: 0.8rem;
                }

                &__item {
                    display: flex;
                }

                &__keyword-banner-item {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 1.3rem 2.5rem !important;
                    background-color: color('ui-spot');
                    background-color: var(--color-primary);
                    color: color('background') !important;
                    color: var(--color-background) !important;
                }

                &__item-data-container {
                    display: flex;
                    width: 100%;
                }

                &__item-fact {
                    display: flex;
                    align-items: center;
                    width: 30%;
                    flex-grow: 1;
                }

                &__result-box {
                    padding-top: 10px;
                }

                &__keyword-banner-result-box {
                    padding: 0 !important;
                }

                span.netprice-info {
                    b {
                        font-weight: normal;
                    }

                    @include mediaquery('md', 'lte') {
                        display: block;
                    }
                }

                &__result-box {
                    img {
                        max-width: 15rem;
                        min-height: 8rem;

                        &[src=''] {
                            display: none;
                        }

                        @include mediaquery('md', 'lte') {
                            min-height: auto;
                            max-width: 13rem;
                        }

                        @include mediaquery('sm', 'only') {
                            display: none;
                        }
                    }

                    &__car-fact {
                        display: flex;
                        align-items: center;
                    }

                    .global-search-bar {
                        &__item-data-container {
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;
                            min-height: 8rem;

                            @include mediaquery('md', 'lte') {
                                min-height: auto;
                                padding-left: 0rem;
                            }
                        }

                        &__car-fact {
                            display: none;

                            @include mediaquery('lg', gte) {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding-top: 5px;
                                padding-bottom: 5px;

                                span,
                                svg {
                                    display: block;
                                }
                            }
                        }

                        &__car-fact__text {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
