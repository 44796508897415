.saleshub {
    &__facet {
        .select .select__wrapper {
            color: color('main');
            color: var(--color-main);
        }
    }

    &__tag-bar {
        margin: 0 -#{$grid-width/2} $baseline;
    }

    &__remove-tag,
    &__tag {
        cursor: pointer;
        margin: 0 #{$grid-width/2};

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    &__results__list__item {
        padding-left: 0;
        padding-right: 0;
    }

    &__item {
        &__card {
            position: relative;
            height: calc(100% - 3rem);
            margin: 1.5rem;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        &__image {
            position: relative;
            height: 240px;

            img {
                display: block;
                width: 100%;
                height: 240px;
                object-fit: cover;
                transition: 0.25s;
            }
        }

        &__headline {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            color: color('background');
            color: var(--color-background);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-transform: none;

            > * {
                margin: 0;
            }
        }

        &__link:hover {
            .saleshub__item__image img {
                transform: scale(1.05);
            }
        }
    }
}
