$component: '.infobox';
$component2: '.paymentwrapper';
$component3: '.socialmediafooter';

#{$component3} {

  .only-for-mobile {
    display: none;
  }
  @include mediaquery("md", smaller) {
    .only-for-desktop {
      display: none;
    }
    .only-for-mobile {
      display: block;
    }
  }


  .collapse {
    &__title {
      padding: 0;
      padding-right: 0;
      background-color: initial;
      min-height: initial;


      &:focus {
        box-shadow: none;
      }
    }

    &__content {
      padding: 0;
    }

    &__icon {
      display: none;
    }

    @include mediaquery("md", smaller) {
      margin-bottom: 20px;

      &__title {
        display: block;
        min-height: 40px;
        position: relative;
      }

      .hl {
        display: inline-block;
        position: relative; // overwrite
        padding-right: 40px;
      }

      &__icon {
        top: 0;
        right: 0;
        margin-left: 30px;
        display: block;
        position: absolute; // overwrite
      }
    }
  }

  padding-top: 50px;
  width: 100%;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0px;
  }

  p {
    color: #696969;
    font-size: 1.8rem;
    line-height: 3rem;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 0.6rem;
  }

  .socialmedialist {
    line-height: 1rem;
    padding-bottom: 30px;
  }

  a {
    list-style: none;
    padding-top: 1rem;
    display: flex;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;

    }

    img {
      margin: -2px 10px -5px 0;
      width: 28px;
      height: 28px;
    }
  }
}

#{$component2} {

  p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 2.6rem;
    line-height: 3rem;
    letter-spacing: 0.0278em;
    font-weight: 600;
  }

  .grid__cell {
      border-right: 1px lightgrey solid;
  }

  @include mediaquery("md", "lte") {
    .grid__cell {
      border-right: 0;
      border-bottom: 1px lightgrey solid;
      padding-bottom: 2rem;
      margin-bottom: 3rem;

      margin-left: 2rem;
      margin-right: 2rem;

      .hl {
        margin-bottom: 1rem;
      }

    }
  }

  .grid__cell:last-child {
    border: 0;
  }

  .img__wrapper {
    margin-top: auto;

    width: 100%;
    height: 160px;


    svg.icon {
      height: 100%;
      width: 100%;
      stroke: transparent;

    }
  }


  &__hl {
    @include mediaquery('md', 'lte') {
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.055em;
    }

    > span {
      display: block;
    }
  }

  &__iframe-container {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 205px;
  }

  &__iframe-wrapper {
    position: relative;
    display: block;
    margin: -3rem 0;
    width: 100%;
    height: 0;
    padding-bottom: 113.636%;

    > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    list-style: none;
    margin: 0.5em 0 3em;
    padding: 0;

    > li {
      margin: 0;
      padding: 0;

      > a {
        display: flex;
        padding: 0.2em 0;
        color: inherit;
        text-decoration: none;

        &:hover {
          span {
            text-decoration: underline;
          }
        }

        img {
          margin: -2px 10px -5px 0;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &__black {
    color: color(background);

    a {
      color: color(background);
    }

    .content-wrapper {
      background-color: color('main');
      background-color: var(--color-main);
      box-sizing: border-box;
    }
  }

  &__flex {
    flex-wrap: wrap;
    padding: 2.6rem 0 2rem;
    align-items: flex-end;
  }

  &__logo-row,
  &__other-row {
    display: flex;
    align-items: flex-end;
    margin: 1rem 0;

    > a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__other-row {
    > *:not(:first-child) {
      margin-left: 2rem;
    }
  }

  &__logo {
    width: 12.9rem;
    padding-right: 6rem;
  }

  &__hr {
    border: 1px solid currentColor;
    border-width: 1px 0 0;
  }

  &__hint {
    padding-bottom: 2.6rem;

    p {
      @extend .text-small;
      margin: 0;
    }
  }
}

#{$component} {
  @include big-margin-bottom;

  &__hl {
    margin-top: 0;
    margin-bottom: $baseline * 2;
    text-align: center;
  }

  &__cell--icon {
    position: relative;
    padding-left: ($grid-width / 2) + 3rem + $grid-width;

    @include mediaquery('lg') {
      padding-left: $grid-width + 3rem + $grid-width;
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: -0.5rem;
    left: $grid-width / 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mediaquery('lg') {
      top: -0.5rem;
      left: $grid-width;
    }
  }
}
