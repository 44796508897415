.cockpit {

  #email {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  .email-label .form__label {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  &__fieldset {
    &__headline {
      .hl {
        padding: 0 0 1rem 0;
        color: black;
        border: 0;
        border-bottom: 3px solid var(--color-primary);
        outline: 0;
        line-height: 2.2rem;
        letter-spacing: 0.055em;
      }
    }
  }

  .grid__cell {
    position: relative;
  }

  &__overlay {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
      max-width: 500px;
      text-align: center;
    }
  }

  &__data {
    &__form {
      &[data-mode='view'] {
        .form__input__edit,
        .form__button__submit {
          display: none;
        }
      }

      &[data-mode='edit'] {
        .form__input__view,
        .form__button__edit {
          display: none;
        }
      }

      .form__input {
        &__view {
          .view__label {
            display: block;
            margin-bottom: 5px;
          }

          .view__input {
          }
        }

        &__edit {
        }
      }
    }
  }

  .invoices__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid color('ui-soft');
      border-bottom-color: var(--color-ui-soft);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__name {
      flex: 1;
    }

    &__view,
    &__download {
      flex: 0 1 auto;
      margin-left: 15px;

      svg {
        transition: all 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(1.1);
      }
    }
  }

  .alerts__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid color('ui-soft');
      border-bottom-color: var(--color-ui-soft);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__name {
      flex: 1;
    }

    &__view {
      flex: 0 1 auto;
      margin-right: 5px;
      width: 100%;
      min-width: 170px;
      max-width: 200px;

      svg {
        transition: all 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(1.1);
      }
    }
  }

  &__myalerts {
    .myalert {
      &infos {
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          margin-bottom: 10px;
          display: flex;
        }

        &__label {
          flex: 1;
        }

        &__content {
          font-weight: bold;
          flex: 0 1 auto;
        }
      }
    }
  }

  &__mycars {
    .mycar {
      &infos {
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          margin-bottom: 10px;
          display: flex;
        }

        &__label {
          flex: 1;
        }

        &__content {
          font-weight: bold;
          flex: 0 1 auto;
        }
      }
    }
  }
}
