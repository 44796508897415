.contactlist__inner {
    display: flex;
    flex-wrap: wrap;

    .contactperson {
        margin: 10px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 300px;
        min-width: 165px;
    }
}
