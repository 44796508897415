$componentFacet: '.productsearch-facet';
$componentPage: '.productsearch-page';

#{$componentPage} {
  @include mediaquery('lg', 'lt') {
    margin-top: -$baseline;
  }
}


#{$componentFacet} {

  margin-top: 2px;

  &__hl {
    cursor: default;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 0.055em;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: $grid-width;
  }

  &__hl-icon {
    display: block;
    position: absolute;
    right: 14px;
    top: 8px;
    pointer-events: none;
    @include mediaquery('xl') {
      display: none;
    }
  }

  &__car-icon {
    margin: -8px 7px -7px 0;
    float: left;
    height: 30px;
  }

  &__car-label {
    display: block;
    float: left;
    width: 70px;
  }

  &__type-count {
    display: inline-block;
    box-sizing: border-box;
    min-width: 50px;
    margin-right: $grid-width;
  }

  &__facet-config-container {
    cursor: pointer;
  }

  &__facet-config-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -35px;
    margin-left: -35px;
    margin-bottom: -55px;
  }

  &__facet-config-car-icon {
    width: 100px;
    height: 100px;
  }

  &__facet-config-label svg path {
    fill: currentColor;
  }

  &__facet-config-collapse {
    > button {
      color: var(--color-background);
      background-color: var(--color-primary);
    }
  }

  &.mod--collapseable {
    #{$componentFacet} {
      &__hl {
        position: relative;
        display: block;
        cursor: pointer;
        margin: 0 -#{$grid-width} 0.2rem;
        box-sizing: border-box;
        min-height: 4.8rem;
        padding: 1.3rem 6rem 1.3rem 3rem;
        color: black;
        background-color: color('ui-soft');
        background-color: var(--color-ui-soft);
        border: 0;
        outline: 0;
        text-transform: none;
        font-size: 1.8rem;
        line-height: 2.2rem;
        letter-spacing: 0.055em;
      }

      &__hl-icon {
        transition: transform 0.25s;
      }

      &__wrapper {
        position: relative;
        overflow: hidden;
        margin: 0 -#{$grid-width};
        padding: 0 $grid-width;
        transition: height 0.35s $ease-out;
      }

      &__form {
        padding-top: $grid-width - 0.2rem;
        padding-bottom: $grid-width;
      }

      &[aria-expanded='true'] {
        #{$componentFacet} {
          &__hl-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}

$componentResult: '.productsearch-result';

#{$componentResult} {
  @include mediaquery('lg', 'lte') {
    position: relative;
    padding-top: $block-height + $grid-width;
  }

  &__sort {
    @include mediaquery('lg', 'lte') {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      margin-left: -#{$grid-width};
      margin-right: -#{$grid-width};
      width: calc(100% + #{$grid-width * 2});

      .select__select {
        font-weight: 700;
      }
    }
  }

  &__header {
    align-items: center;
  }

  &__hl {
    margin-bottom: 0;
  }

  &__tag-bar {
    margin: 0 -#{$grid-width/2} $baseline;
  }

  &__remove-tag,
  &__tag {
    cursor: pointer;
    margin: 0 #{$grid-width/2};

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__card {
    margin-bottom: $grid-width;

    .s-car-card {
      &__facts li div {
        display: inline-block;
      }

      &__energy-efficiency-tag {
        margin-top: 4px;
      }
    }
  }

  .carslider {
    &__thumbnails-wrapper {
      display: none;
    }
  }

  .eventually-display-none {
    display: none;
  }

  @include mediaquery('x4l', 'gte') {
    .s-car-card__key-facts {
      margin-bottom: 1.2rem;
    }

    .flex .s-car-card__price-bar {
      margin-bottom: 0.2rem !important;
    }

    .s-car-card__price-bar {
      display: flex;
      align-items: center;

      .empty-placeholder {
        visibility: hidden;
        display: none;
      }
    }
  }
}
