$component: '.footer';

#{$component} {
  &__img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  &__advantages {
    margin-bottom: 7rem;

    .grid__cell {
      border-right: 1px lightgrey solid;
      display: flex;
      flex-direction: column;

      &:last-child {
        border: 0;
      }

      @include mediaquery('md', 'lte') {
        margin-bottom: 5rem;
        border-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .img__wrapper {
      margin-top: auto;
    }

    .footer__img {
      width: 100%;
      height: auto;
      max-width: 150px;

      &.payment {
        max-width: 170px;
      }
    }
  }

  &__hl {
    @include mediaquery('md', 'lte') {
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.055em;
    }

    > span {
      display: block;
    }
  }

  &__iframe-container {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 205px;
  }

  &__iframe-wrapper {
    position: relative;
    display: block;
    margin: -3rem 0;
    width: 100%;
    height: 0;
    padding-bottom: 113.636%;

    > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    list-style: none;
    margin: 0.5em 0 3em;
    padding: 0;

    > li {
      margin: 0;
      padding: 0;

      > a {
        display: flex;
        padding: 0.2em 0;
        color: inherit;
        text-decoration: none;

        &:hover {
          span {
            text-decoration: underline;
          }
        }

        img {
          margin: -2px 10px -5px 0;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &__black {
    color: color(background);

    a {
      color: color(background);
    }

    .content-wrapper {
      background-color: color('main');
      background-color: var(--color-main);
      box-sizing: border-box;
    }
  }

  &__flex {
    flex-wrap: wrap;
    padding: 2.6rem 0 2rem;
    align-items: flex-end;
  }

  &__logo-row,
  &__other-row {
    display: flex;
    align-items: flex-end;
    margin: 1rem 0;

    > a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__other-row {
    > *:not(:first-child) {
      margin-left: 2rem;
    }
  }

  &__logo {
    width: 12.9rem;
    padding-right: 6rem;
  }

  &__hr {
    border: 1px solid currentColor;
    border-width: 1px 0 0;
  }

  &__hint {
    padding-bottom: 2.6rem;

    p {
      @extend .text-small;
      margin: 0;
    }

    @include mediaquery("md", "lte") {
      padding-bottom: 0rem;
      padding-top: 0rem;
    }
  }

  &__socialmedia {
    &__hl {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

}
