$font-prefix: '../fonts/';

@font-face {
    font-family: 'Beresa Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Beresa Sans'), local('BeresaSans'),
        url('#{$font-prefix}WOFF2/DaimlerCS-Light.woff2') format('woff2'),
        url('#{$font-prefix}WOFF/DaimlerCS-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Beresa Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Beresa Sans'), local('BeresaSans'),
        url('#{$font-prefix}WOFF2/DaimlerCS-Regular.woff2') format('woff2'),
        url('#{$font-prefix}WOFF/DaimlerCS-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Beresa Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Beresa Sans'), local('BeresaSans'),
        url('#{$font-prefix}WOFF2/DaimlerCS-Demi.woff2') format('woff2'),
        url('#{$font-prefix}WOFF/DaimlerCS-Demi.woff') format('woff');
    font-display: swap;
}
