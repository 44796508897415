.contactsearch {

    &__content {
        @extend .margin-top-2;
        @include big-margin-bottom;
    }

    &__tag-bar {
        margin: 0 -#{$grid-width/2} $baseline;
    }

    &__remove-tag,
    &__tag {
        cursor: pointer;
        margin: 0 #{$grid-width/2};

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    &__results {
        &__list {
            align-items: stretch;

            &__item .card {
                height: calc(100% - 3rem);
            }
        }
    }
}
