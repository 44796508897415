// Hubstyles (Jobslist)
.jobshub {
  &__facet {
    .select .select__wrapper {
      color: color('main');
      color: var(--color-main);
    }
  }

  &__tag-bar {
    margin: 0 -#{$grid-width/2} $baseline;
  }

  &__remove-tag,
  &__tag {
    cursor: pointer;
    margin: 0 #{$grid-width/2};

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__results__list__item {
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    &__card {
      height: calc(100% - 3rem);
      margin: 1.5rem;
      display: flex;
      flex-direction: column;
      background-color: color('ui-spot-jobs');
      background-color: var(--color-primary);
      color: color('background');
      color: var(--color-background);
    }

    &__image {
      position: relative;
      height: 240px;

      img {
        display: block;
        width: 100%;
        height: 240px;
        object-fit: cover;
      }

      &.large,
      &.large img {
        @include mediaquery('lg') {
          height: 440px;
        }
      }
    }

    &__headline {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      color: color('background');
      color: var(--color-background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: none;
      padding: $grid-width;

      > * {
        margin: 0;
      }

      @include mediaquery('lg', 'lte') {
        h3 {
          font-size: 2rem;
        }

        h4 {
          font-size: 1.5rem;
        }
      }
    }

    &__link {
      display: block;
      height: 100%;
    }
  }
}

body {
  &.contains-component-jobshub,
  &.contains-component-jobitem {
    .muuuh-whatsapp-trigger-btn, #jumpToService, #aisearch__widget-fab-button {
      display: none !important;
    }
  }
}

body.contains-component-jobitem {
  @include mediaquery("md", smaller) {
    padding-top: 12.8rem;

    .hero:first-of-type + div {
      margin-top: 0 !important;
    }
  }
}

// Jobstyles (Single item)
.job {

  &__hero .hero__image img {
    width: 100%;
  }

  .hl {
    font-weight: 600;
    font-size: 2em;
    line-height: 1em;

    &--one,
    &--two,
    &--three {
      font-size: 2em;
    }

    &.job__hl--content {
      font-weight: 400;
      margin: 0;
      font-size: 1.5em;
    }
  }

  &__hl {
    color: color('ui-spot-jobs');
    color: var(--color-primary);

  }

  &__content ul {
    list-style: "- ";
  }

  .no-content,
  .only-image {
    .hero__wrapper {
      display: block;
    }
  }

  &__header {
    .job__hl {
      margin-bottom: 1rem;

      &--three {
        color: lighten(color('ui-spot-jobs'), 25%);
        //color: var(--color-primary-lighten);
      }
    }
  }

  &__description {
  }

  &__apply {
  }

  &__button {
    background-color: color('ui-spot-jobs');
    background-color: var(--color-primary-button);

    &:hover {
      background-color: color('ui-spot-jobs');
      background-color: var(--color-primary-lighten);
    }
  }
}
