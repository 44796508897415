.threesixtymodal {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    transition: 0.25s;
    cursor: pointer;

    opacity: 0;
    visibility: hidden;

    &.visible {
        opacity: 1;
        visibility: visible;

        .threesixtymodal__wrapper {
            opacity: 1;
            transform: scale(1);
        }
    }

    &__wrapper {
        position: relative;
        background-color: white;
        width: calc(100vw - #{$grid-width * 2});
        opacity: 0;
        transform: scale(0.8);
        transition: 0.5s;

        @include mediaquery('lg') {
            width: calc(100vw - #{$grid-width * 4});
            max-width: 1400px;
            height: 600px;
        }
    }

    &__headline {
        box-sizing: border-box;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 68px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &__wrapper {
            display: flex;
            padding: 10px;
            flex-direction: row;
            align-items: center;
            max-width: 700px;
            width: 100%;

            @include mediaquery('lg') {
                padding: 10px 50px;
            }

            & > * {
                flex: 1;
            }
        }

        &__separator {
            text-align: center;
            flex: 0 1 auto;
            margin: 0 10px;
        }
    }

    &__close {
        position: absolute;
        display: none;
        top: 18px;
        right: 18px;
        cursor: pointer;
        z-index: 3;

        @include mediaquery('lg') {
            display: block;
        }
    }

    &__content {
        position: relative;
    }

    &__button {
        font-size: 1.4rem !important;
        padding-top: 0.65rem !important;
        padding-bottom: 0.65rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        min-height: 3.2rem;
        line-height: 1.6rem;

        @include mediaquery('lg') {
            font-size: 1.8rem !important;
            padding-top: 1.25rem !important;
            padding-bottom: 1.25rem !important;
            padding-left: 2.5rem !important;
            padding-right: 2.5rem !important;
            min-height: 4.8rem;
            line-height: 2.2rem;
        }

        &.mod--shy {
            background-color: white;
        }
    }

    &__360,
    &__panorama {
        display: none;

        &.visible {
            display: block;
        }
    }

    &__360 {
    }

    &__panorama {
    }

    &__description {
        box-sizing: border-box;
        width: 100%;
        padding: #{$grid-width / 2};
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        font-size: 1.4rem;
        display: none;

        @include mediaquery('lg') {
            font-size: 1.8rem;
            padding: #{$grid-width};
            display: block;
        }
    }

    .threesixtymodal__360__preloader {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }

    #threesixtymodal__panorama__viewer,
    #threesixtymodal__360__viewer {
        cursor: move;
    }

    #threesixtymodal__360__viewer {
        background-size: cover;
        background-position: center;
    }

    #threesixtymodal__panorama__viewer {
        width: 100%;
        height: 300px;
        background-image: none !important;
    }
}
