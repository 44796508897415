
.colored-headerbanner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 40px 60px;

  @include mediaquery("lg", gte){
    padding: 124px 40px 124px;
  }

  color: color('main');
  background-color: var(--color-primary);

  .hl, h1, h2 {
    font-size: 4vw;
    line-height: 4vw;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0px;
  }

  .speech-bubble {
    position: relative;
    display: block;
    max-width: 1000px;
    text-decoration: none;
    color: white;
    border-bottom: 2px solid white;
    padding-bottom: 16px;
    margin-top: 88px;
    margin-bottom: 70px;


    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 74px solid transparent;
      border-top-color: white;
      border-bottom: 0;
      border-left: 0;
      margin-left: -3px;
      margin-bottom: -74px;
      -webkit-transform: skewX(-12deg);
      -ms-transform: skewX(-12deg);
      transform: skewX(-12deg);
    }

    &::after {
      content: '';
      position: absolute;
      margin-left: -1px;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 70px solid transparent;
      border-top-color: white;
      border-top-color: var(--color-primary);
      border-bottom: 0;
      border-left: 0;
      margin-bottom: -70px;
      -webkit-transform: skewX(-12deg);
      -ms-transform: skewX(-12deg);
      transform: skewX(-12deg);
    }
  }
}

@media only screen and (max-width: 800px) {
  .colored-headerbanner {
    padding: 48px 40px 48px;

    .speech-bubble {
      margin-bottom: 50px;
      font-size: 4vw;
      line-height: 4.5vw;

      .hl {
        font-size: 5vw;
        line-height: 5.5vw;
      }

      &::before {
        border: 36px solid transparent;
        margin-bottom: -36px;
        border-top-color: white;
        border-bottom: 0;
        border-left: 0;
      }

      &::after {
        border: 32px solid transparent;
        margin-bottom: -32px;
        margin-left: -1px;
        border-top-color: white;
        border-top-color: var(--color-primary);
        border-bottom: 0;
        border-left: 0;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .colored-headerbanner {

    .speech-bubble {
      font-size: 4rem;
      line-height: 4.5rem;
    }

    .hl, h1, h2 {
      font-size: 6rem;
      line-height: 6.5rem;
    }
  }
}
