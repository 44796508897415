$componentResult: '.contentteaser';

.contenthead__wrapper .contentteaser {
  @include big-margin-bottom;

  @include mediaquery('lg', smaller) {
    margin-bottom: 3rem;
  }
}

#{$componentResult} {

  margin-bottom: 20px;

  &__hl {
    margin-bottom: $baseline * 2;
    text-align: center;
  }

  &__swiper-wrapper {
    position: relative;
  }

  &__item {
    position: relative;
    min-height: 52rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pagination {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: $grid-width;
  }

  &__bullet {
    display: inline-block;
    color: color('ui-strong');
    color: var(--color-ui-strong);
    border: solid 2px currentColor;
    width: 11px;
    height: 11px;
    border-radius: 8px;
    margin: 0 10px;
    outline: none;
    cursor: pointer;

    &:focus {
      @include form-focus-style();
    }

    &.swiper-pagination-bullet-active {
      color: color('main');
      color: var(--color-main);
      background-color: currentColor;
    }
  }

  &__tag-bar {
    margin: 0 -#{$grid-width/2} $baseline;
  }

  &__remove-tag,
  &__tag {
    cursor: pointer;
  }

  &__tag {
    margin: 0 #{$grid-width/2};
  }

  .tns-outer [data-action='stop'],
  .tns-outer [data-action='start'] {
    display: none;
  }

  .tns-nav {
    z-index: 9;
    display: flex;
    justify-content: center;
    padding-top: $grid-width * 2;

    > * {
      margin: 0 #{$grid-width / 2};
      padding: 0;
      box-sizing: border-box;
      height: $grid-width;
      width: $grid-width;
      border: solid 2px color('ui-strong');
      border-color: var(--color-ui-strong);
      border-radius: 50%;
      outline: 0;
      background-color: transparent;

      &.tns-nav-active {
        border-color: color('main');
        border-color: var(--color-main);
        background-color: color('main');
        background-color: var(--color-main);
      }
    }
  }

  .contentimage__headline {
    text-align: inherit;
  }

  .contentimage__text {
    display: flex;
    flex-direction: column;

    &.vertical-top {
      justify-content: flex-start;
    }
    &.vertical-center {
      justify-content: center;
    }
    &.vertical-bottom {
      justify-content: flex-end;
    }
  }

  .contentimage__innercontent {
    width: 100%;
    p {
      margin-block-start: 0;
    }
  }

  .contentimage__button__wrappper {
    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
}

.contentslider {
  position: relative;

  svg.icon {
    stroke: var(--color-white);
  }

  &__control {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: calc(50% - 16px);
    outline: none;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }

    &[aria-disabled='true'] {
      opacity: 0;
      cursor: auto;
    }

    svg {
      display: block;
    }
  }
}
