#CybotCookiebotDialog {
    @extend .text-body;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25) !important;

    // Text body
    #CybotCookiebotDialogBody {
        padding: 20px;
        @extend .text-body;

        a,
        div {
            @extend .text-body;
        }

        #CybotCookiebotDialogBodyContent {
            @extend .text-body;

            // Headline
            #CybotCookiebotDialogBodyContentTitle {
                @extend .text-body;
                @extend .hl--body;
                margin-bottom: 1rem;
            }

            // Text
            #CybotCookiebotDialogBodyContentText {
                @extend .text-body;
                @extend .text-small;
                letter-spacing: 0;
            }
        }
    }

    // Button und Cookie Buttons
    #CybotCookiebotDialogBodyLevelWrapper {
        @extend .text-body;
        @extend .text-small;

        a,
        div {
            @extend .text-body;
            @extend .text-small;
        }

        // Button Accept Wrapper
        #CybotCookiebotDialogBodyLevelButtonAcceptWrapper {
            #CybotCookiebotDialogBodyLevelButtonAccept {
                @extend .button;
                @extend .mod--block;
                padding: 3px 5px;
                min-height: auto;
            }
        }

        // Cookie Buttons
        #CybotCookiebotDialogBodyLevelButtons {
            #CybotCookiebotDialogBodyLevelButtonsTable {
                @extend .text-small;
            }
        }
    }

    // Detailinfos zu Cookies
    #CybotCookiebotDialogDetail {
        @extend .text-small;
        letter-spacing: 0;

        a,
        div {
            @extend .text-body;
            @extend .text-small;
            letter-spacing: 0;
        }
    }
}
