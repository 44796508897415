$component: '.aisearch';

#{$component} {
  &__container {
    z-index: 1000;
    height: 52px;
    width: 52px;
    background-color: #fff;
    position: fixed;
    bottom: 150px;
    right: 10px;
    margin: 15px;
    border-bottom-color: #fff;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    //border-image-outset: 0;
    border-image-width: 1;
    border-left-color: #fff;
    border-left-style: solid;
    border-left-width: 2px;
    border-right-color: #fff;
    border-right-style: solid;
    border-right-width: 2px;
    border-top-color: #fff;
    border-radius: 50%;
    border-top-style: solid;
    border-top-width: 2px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    opacity: 0.85;
    cursor: pointer;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
    align-items: center;
    justify-content: center;
    display: none;
  }

  &__icon {
    width: 34.59px;
    height: 34.59px;
  }
}

$banner-component: '.ai-banner';
#{$banner-component} {
  &__container {
    position: relative;
    margin-bottom: 15px;
  }

  &__icon-container {
    background: transparent;
    display: flex;
    flex-direction: column;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 1;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__icon-image-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    align-items: center;
  }

  &__icon-image-container > img {
    width: 65px;
    height: 64px;
  }

  &__background-image-container {
    height: 270px;
    width: 100%
  }

  &__background-image-container > img {
    width: 100%;
    height: 270px;
    object-fit: cover;
  }

  &__title {
    color: white;
    font-family: Beresa Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    left: 10%;
    letter-spacing: .055em;
    line-height: 3.5rem;
    text-transform: uppercase;
    top: 10%;
    padding-left: 10px;
  }

  &__description {
    color: white;
    font-family: Beresa Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    left: 15px;
    letter-spacing: .055em;
    line-height: 1.8rem;
    margin-bottom: 25px;
  }

  &__button {
    color: white;
    font-family: Beresa Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    left: 15px;
    max-width: 35%;
    vertical-align: center;
  }

  /* Mobile-specific styles */
  @media (max-width: 1050px) {
    &__background-image-container {
      height: 320px;
    }

    &__background-image-container > img {
      height: 320px;
    }
  }

  @media (max-width: 768px) {
    &__icon-container {
      left: 10px;
      top: 10px;
    }

    &__icon-image-container {
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-size: 2rem;
      line-height: 2.2rem;
    }

    &__description {
      font-size: 1.5rem;
      line-height: 1.6rem;
      margin-bottom: 20px;
      left: 10px;
    }

    &__button {
      max-width: 50%;
      padding: 10px;
      font-size: 1.5rem;
    }

    &__background-image-container {
      height: 270px;
    }

    &__background-image-container > img {
      height: 270px;
    }
  }

  @media (max-width: 420px) {
    &__background-image-container {
      height: 320px;
    }

    &__background-image-container > img {
      height: 320px;
    }
  }
}