
.href-wrapper-for-simple-content {
  color: inherit;
  text-decoration: none;
}

.contentsimple {
  &__content {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--color-primary);
    }

    // HEADER STYLES
    h1 {
      font-size: 36px;
      line-height: 45px;
      @include mediaquery("sm", "only") {
        line-height: 34px;
        font-size: 27px;
      }
      @include mediaquery("md", "only") {
        line-height: 34px;
        font-size: 27px;
      }
    }

    h2 {
      letter-spacing: 0.055em;
      font-size: 2.6rem;
      line-height: 3rem;
    }

    h2,
    h3,
    h4 {
      text-transform: uppercase;
      font-weight: 600;
    }

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style: "- ";
    }
  }

  &--border-left {
    position: relative;

    @include mediaquery('lg') {
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -1.5rem;
        bottom: 6px;
        width: 1px;
        background-color: color('main');
        background-color: var(--color-main);
      }
    }
  }

  &--bubble {
    background-color: color('ui-content-spot');
    background-color: var(--color-primary);
    color: color('background');
    color: var(--color-background);
    padding: $padding;
    position: relative;
    margin-bottom: 3rem;

    a {
      color: var(--color-background);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 30px solid transparent;
      border-top-color: color('ui-content-spot');
      border-top-color: var(--color-primary);
      border-bottom: 0;
      border-left: 0;
      margin-left: -1.5rem;
      margin-bottom: -3rem;
      transform: skewX(-12deg);
    }
  }

  &--withbackgroundimage {
    position: relative;

    .contentsimple--bubble--wrapper {
      position: absolute;
      right: 30px;
      top: 30px;
      opacity: 0.9;
      //max-height: calc(100% - 60px);
      max-width: 100%;
      display: block;
      //overflow-y: auto;
      //overflow-x: hidden;
    }

    .bubble--img {
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }

    .contentsimple--bubble {
      margin-left: 30px;
    }
  }
}

//Under 768px the grid system switch to one content-cell per grid-row
@media only screen and (min-width: 768px) {
    .mod--span-lg-4 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper {
      width: calc(100% - 30px);
    }

    .mod--span-lg-8 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper {
      width: calc(50% - 15px);
    }

    .mod--span-lg-12 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper {
      width: calc(33% - 15px);
    }
}

@media only screen and (max-width: 767px) {
  .mod--span-lg-4 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper,
  .mod--span-lg-8 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper,
  .mod--span-lg-12 .contentsimple--withbackgroundimage .contentsimple--bubble--wrapper {
    width: calc(100% - 30px);
  }
}
