$component: '.roles-change';

#{$component} {
  &__input-hide {
    display: none !important;
  }

  &__user-title {
    display: flex;
    align-items: center;
  }
}