#nprogress {
    .bar {
        background: color('ui-spot');
        background: var(--color-primary);
    }

    .peg {
        box-shadow: 0 0 10px color('ui-spot'), 0 0 5px color('ui-spot');
        box-shadow: 0 0 10px var(--color-primary), 0 0 5px var(--color-primary);
    }

    .spinner-icon {
        border-top-color: color('ui-spot');
        border-top-color: var(--color-primary);
        border-left-color: color('ui-spot');
        border-left-color: var(--color-primary);
        display: none;
    }
}
