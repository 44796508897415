$componentIcon: '.icon';

#svg-sprite {
    @extend .visuallyhidden;
}

#{$componentIcon} {
    display: inline-block;
}

#{$componentIcon} {
    width: 32px;
    height: 32px;
    stroke: currentColor;

    &.mod--background,
    &.mod--white {
        stroke: color('background');
        stroke: var(--color-background);
    }
    &.mod--main,
    &.mod--black {
        stroke: color('main');
        stroke: var(--color-main);
    }
    &.mod--red {
        stroke: color('beresa-red');
        stroke: var(--color-red-light);
    }
}

