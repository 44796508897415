.accordion {

    h1 {
        line-height: 3.8rem;
    }

    &__item {
        border: 2px solid color('ui-soft');
        border-color: var(--color-ui-soft);
    }

    &__item--lvl-1 {
        > .collapse > .collapse__title {
            background-color: var(--color-primary);
            color: var(--color-background);
        }

        > .collapse > .collapse__flyout > .collapse__content {
            padding: #{($block-height - $baseline) / 2};
        }
    }

    &__item--lvl-2 {
        border: 0;
        border-bottom: 2px solid color('ui-soft');
        border-bottom-color: var(--color-ui-soft);

        .collapse__title {
            padding: #{($block-height - $baseline) / 2} 0;
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;

            .collapse__icon {
                right: 0;
            }
        }

        .collapse__content {
            padding: 0 0 #{($block-height - $baseline) / 2} 0;
        }
    }

    &__item--lvl-2:last-child {
        margin-bottom: 0 !important;
        border-bottom: 0 !important;
    }
}
