@media print {
  iframe,
  .header,
  .footer,
  .jobitem-print-btn,
  .contentsimple--bubble {
    display: none;
  }
}

.jobdetail {

  .jobitem-print-btn {
    float: right;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__hero .hero__image img {
    width: 100%;
  }

  .hl {
    font-weight: 600;
    font-size: 2em;
    line-height: 1em;
    position: relative;

    &--one,
    &--two,
    &--three {
      font-size: 2em;
    }

    &.job__hl--content {
      font-weight: 400;
      margin: 0;
      font-size: 1.5em;
    }
  }

  &__hl {
    color: color('ui-spot-jobs');
    color: var(--color-primary);

  }

  &__content ul {
    list-style: "- ";
  }

  .no-content,
  .only-image {
    .hero__wrapper {
      display: block;
    }
  }

  &__header {
    .job__hl {
      margin-bottom: 1rem;

      &--three {
        color: lighten(color('ui-spot-jobs'), 25%);
        //color: var(--color-primary-lighten);
      }
    }
  }

  &__description {
  }

  &__apply {
  }

  &__button {
    background-color: color('ui-spot-jobs');
    background-color: var(--color-primary-button);

    &:hover {
      background-color: color('ui-spot-jobs');
      background-color: var(--color-primary-lighten);
    }
  }
}

