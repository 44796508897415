.countdown {
    &__target {
        color: var(--color-primary);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        text-align: center;

        &:not(:first-child) {
            margin-left: 20px;

            @include mediaquery('lg') {
                margin-left: 40px;
            }
        }
    }

    &__hl {
        margin-bottom: 10px;
    }
}
