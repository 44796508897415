$shoppingCartSummary: '.shoppingcart-summary';

#{$shoppingCartSummary} {
  &__container {
    display: flex;
    flex-direction: column;
    background-color: color('light-grey');
    padding: 10px;
  }

  &__count-container {
    display: flex;
    flex-direction: row;
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  &__price-entry {
    padding-top: 10px;
  }

  &__price-padding {
    padding-top: 20px;
  }

  &__discount-container {
    padding-top: 20px;
  }
}


$shoppingCartResult: '.shoppingcart-result';

#{$shoppingCartResult} {
  &__hl {
    padding-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 75px 10px;
  }

  &__price-container {
    align-content: end;
  }

  &__count-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__price-entry {
    text-align: right;
  }

  &__price-padding {
    padding-top: 20px;
  }

  &__card {
    margin-bottom: $grid-width;

    &.move {
      transition: all 0.35s $ease-out-out;
    }
  }
}