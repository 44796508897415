$component: '.servicebooking';

#{$component} {

  &__content-container {
    //margin-top: 1rem;
    //margin-bottom: 1rem;
    padding-top: 1.5rem;
    //padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__form__container {
    height: 90px;
    align-items: end;
    justify-content: end;

    @include mediaquery("md", "only") {
      height: auto;
      align-items: end;
      justify-content: end;
    }

    @include mediaquery("sm", "only") {
      height: auto;
      align-items: end;
      justify-content: end;
    }
  }

  &__select__container {
    height: 100%;
    padding-bottom: 1.5rem;
  }

  &__button__container {
    //display: flex;
    //flex-direction: row-reverse;
    //align-items: flex-end;
    //width: 50%;
  }

  &__form__button {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-left: 5px;
    //margin-right: 5px;
    padding-bottom: 1.5rem;
  }
}
