$navheight: 130px;
.b2b {

  #email {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  .email-label .form__label {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  &__fieldset {
    &__headline {
      .hl {
        padding: 0 0 1rem 0;
        color: black;
        border: 0;
        border-bottom: 3px solid var(--color-primary);
        outline: 0;
        line-height: 2.2rem;
        letter-spacing: 0.055em;
      }
    }
  }

  .grid__cell {
    position: relative;
  }

  &__overlay {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
      max-width: 500px;
      text-align: center;
    }
  }

  &__data {
    &__form {
      &[data-mode='view'] {
        .form__input__edit,
        .form__button__submit {
          display: none;
        }
      }

      &[data-mode='edit'] {
        .form__input__view,
        .form__button__edit {
          display: none;
        }
      }

      .form__input {
        &__view {
          .view__label {
            display: block;
            margin-bottom: 5px;
          }

          .view__input {
          }
        }

        &__edit {
        }
      }
    }
  }

  .packages__list, .vehicles__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      margin-bottom: 10px;
      border-bottom: 1px solid color('ui-soft');
      border-bottom-color: var(--color-ui-soft);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__button {
      margin-bottom: 10px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      align-items: right;
    }

    &__name {
      flex: 1;
    }

    &__view,
    &__download {
      flex: 0 1 auto;
      margin-left: 15px;

      svg {
        transition: all 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(1.1);
      }
    }
  }

  .__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid color('ui-soft');
      border-bottom-color: var(--color-ui-soft);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__name {
      flex: 1;
    }

    &__view,
    &__download {
      flex: 0 1 auto;
      margin-left: 15px;

      svg {
        transition: all 0.2s ease-in-out;
      }

      &:hover svg {
        transform: scale(1.1);
      }
    }
  }

  &__mypackages {
    .mypackage {
      &infos {
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          margin-bottom: 10px;
          display: flex;
        }

        &__label {
          flex: 1;
        }

        &__content {
          font-weight: bold;
          flex: 0 1 auto;
        }
      }
    }
  }
}

.b2b-cockpit {
  &__list-search-button-cell {
    margin-top: 29.5px;
  }
}

.deletemodal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: none;

  h1 {
    width: 100%;
    text-align: center;
  }

  &.visible {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttonwrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 24rem;
  }

  &__btn {
    max-width: 24rem;
    margin-top: 10px;
    @include mediaquery("md", smaller) {
      max-width: 100%
    }
  }

  &__close {

  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    background-color: rgba(255, 255, 255, 255);
    display: block;
    padding: 30px;
    margin: 0 20px #{$navheight};
    height: auto;
    max-height: 80%;
    //max-width: 80%;
    max-width: 600px;
    //width: 30%;
    z-index: 1;
    position: relative;
    @include mediaquery("md", smaller) {
      padding: 15px
    }
  }

  &__infowrapper,
  &__namewrapper,
  &__textwrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 60%;
    flex-grow: 1;
    min-width: auto;
    @include mediaquery("md", smaller) {
      justify-content: center;
      align-items: center;
    }
  }

}