$component: '.checkout';

#{$component} {
  margin-bottom: 3rem;

  &__maxwidth {
    max-width: 500px;
  }

  .location--hidden {
    display: none;
  }

  // Shipment selector radios and forms
  .shipment__selector .tiles {
    align-items: center;
  }

  .shipment__form {
    display: none;

    &.active {
      display: block;
    }
  }

  // Extra Accessories / Services list
  .accessories {
    list-style: none;

    li.accessories__item {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;

      .checkbox {
        font-weight: 700;

        &__label {
          margin-top: 3px;
        }
      }
    }

    &__left {
      flex: 1;

      & > p {
        padding-left: calc(20px + 0.75rem);
      }
    }

    &__right {
      flex: 0 1 auto;
      text-align: right;

      p:first-child {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }
  }

  // Payment checkboxes
  .checkbox.mod--payment {
    align-items: center;
  }

  .step-progress__desc {
    @include mediaquery('md', 'lte') {
      display: none;
    }
  }

  &__payment-hr {
    border: solid 2px color('beresa-red');
    border: solid 2px var(--color-primary);
    border-width: 2px 0 0;
    margin: 0 0 #{$grid-width};
  }

  &__payment-dl {
    dt {
      max-width: 75%;
    }
  }

  &__payment-discount-text {
    color: color('beresa-red');
    color: var(--color-red-light);
  }

  &[data-step='0'] {
    #{$component}__maxwidth {
      max-width: none;
    }

    .onlyEquipmentOnCart__maxwidth {
      max-width: 500px;
    }
  }

  .modal.card {
    width: calc(100% - 12rem);
    max-width: calc(600px - 12rem);
  }
}
