.padding-top-1 {
  padding-top: $grid-width !important;
}

.padding-top-2 {
  padding-top: $grid-width * 2 !important;
}

.padding-top-3 {
  padding-top: $grid-width * 3 !important;
}

.padding-top-4 {
  padding-top: $grid-width * 4 !important;
}

.padding-bottom-4 {
  padding-bottom: $grid-width * 4 !important;
}