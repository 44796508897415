@mixin general-slider {

  .general-slider-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-grey-dark-lighten);
      margin-bottom: 0;
  }

  .general-swiper-wrapper {
    display: block;
    width: 100%;
    position: relative;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;

    &:visited,
    &:hover,
    &:focus,
    &:active,
    &:visited:hover {
      background-color: transparent;
      outline-style: none;
      -webkit-tap-highlight-color:transparent;
    }

    &[aria-disabled='true'] {
      opacity: 0;
      cursor: auto;
    }
  }

  .swiper-button-prev {
    right: 100%;

    @include mediaquery('lg', 'lte') {
      margin-right: -1.5rem;
    }
  }
  .swiper-button-next {
    left: 100%;

    @include mediaquery('lg', 'lte') {
      margin-left: -1.5rem;
    }
  }
}