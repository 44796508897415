$component: '.leasing';

#{$component} {
    &__info {
        position: relative;
        left: 5px;
        top: 4px;
        width: 22px;
        height: 22px;
        cursor: pointer;
    }
    &__em-monthly-rate {
        background-color: color('ui-soft');
        background-color: var(--color-ui-soft);
        margin: $grid-width 0;
        padding: $grid-width;
    }
    &__tiles {
        &.mod--cols-lg-2 {
            @include mediaquery('lg') {
                margin-right: -#{$grid-width * 2};
                > *:not(.modal) {
                    width: calc(50% - #{$grid-width * 2}) !important;
                    margin-right: #{$grid-width * 2} !important;
                }
            }
        }
        &.mod--cols-xxl-2 {
            @include mediaquery('xxl') {
                margin-right: -#{$grid-width * 2};
                > *:not(.modal) {
                    width: calc(50% - #{$grid-width * 2}) !important;
                    margin-right: #{$grid-width * 2} !important;
                }
            }
        }
    }
}
