.timeline {
    position: relative;

    &__button {
        position: absolute;
        width: 32px;
        height: 100%;
        z-index: 100;
        top: 0px;
        cursor: pointer;
        background-color: color('background');
        background-color: var(--color-background);

        svg {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &--prev {
            left: -16px;

            @include mediaquery('xl') {
                left: -32px;
            }
        }

        &--next {
            right: -16px;

            @include mediaquery('xl') {
                right: -32px;
            }
        }
    }

    &__wrapper {
        position: relative;
    }

    &__slider {
        overflow: hidden;
        background-color: color('ui-content-spot');
        background-color: var(--color-primary);

        &__content {
            height: 300px;
            display: flex;
            flex-direction: row;
            color: color('background');
            color: var(--color-background);
            position: relative;
        }
    }

    &__range {
        width: 720px;
        min-width: 720px;
        height: 300px;
        position: relative;
        background-color: color('ui-content-spot');
        background-color: var(--color-primary);
        background-size: auto 250px;
        background-repeat: repeat-x;
        background-position: center left;
        cursor: grab;

        &__item {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: color('background');
            background-color: var(--color-background);
            color: color('ui-content-spot');
            color: var(--color-primary);
            font-size: 1.8rem;
            padding: 1rem 2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
            transition: 0.2s;
            opacity: 0.55;
            user-select: none;

            &.active {
                opacity: 1;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 0;
                height: 0;
                border: 16px solid transparent;
                border-top-color: color('background');
                border-top-color: var(--color-background);
                border-bottom: 0;
                border-left: 0;
                margin-left: -0.8rem;
                margin-bottom: -1.6rem;
                transform: skewX(-12deg);
            }

            &:hover {
                transform: scale(0.8);
            }

            &:nth-child(1) {
                top: 24px;
                left: 150px;
            }
            &:nth-child(2) {
                top: 100px;
                left: 210px;
            }
            &:nth-child(3) {
                top: 10px;
                left: 385px;

                &::after {
                    border: 16px solid transparent;
                    border-top-color: color('background');
                    border-top-color: var(--color-background);
                    border-bottom: 0;
                    border-right: 0;
                    transform: skewX(12deg);
                }
            }
            &:nth-child(4) {
                top: 80px;
                left: 550px;
            }
        }
    }

    &__content {
        max-width: 720px;

        &__item {
            transition: opacity 0.25s;
            opacity: 0;
            overflow: hidden;

            &.active {
                transition: opacity 0.25s;
                opacity: 1;
                max-height: 1000px !important;
            }
        }
    }
}
