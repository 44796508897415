.text-body {
    font-family: 'Beresa Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 0;

    &--bold,
    &--caps,
    &--bold-caps {
        @extend .text-body;
    }

    &--bold,
    &--bold-caps,
    &.mod--bold,
    strong {
        font-weight: 700;
    }

    &--caps,
    &--bold-caps,
    &.mod--caps {
        text-transform: uppercase;
    }
}

.text-small {
    font-family: 'Beresa Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;

    &--bold,
    &--caps,
    &--bold-caps {
        @extend .text-small;
    }

    &--bold,
    &--bold-caps,
    &.mod--bold {
        font-weight: 700;
    }

    &--caps,
    &--bold-caps,
    &.mod--caps {
        text-transform: uppercase;
    }
}

.text-hl {
    @extend .text-body;
    font-size: 2.6rem;
    line-height: 3rem;
    letter-spacing: 0.0278em;
    font-weight: 600;

    &--bold,
    &--caps,
    &--bold-caps {
        @extend .text-hl;
    }

    &--bold,
    &--bold-caps,
    &.mod--bold {
        font-weight: 700;
    }

    &--caps,
    &--bold-caps,
    &.mod--caps {
        text-transform: uppercase;
    }
}

.text-big-hl {
    @extend .text-body;
    font-size: 3.8rem;
    line-height: 3.2rem;
    letter-spacing: 0.0208em;
    font-weight: 600;

    @include mediaquery('lg') {
        font-size: 4.8rem;
        line-height: 5.2rem;
    }

    &--bold,
    &--caps,
    &--bold-caps {
        @extend .text-big-hl;
    }

    &--bold,
    &--bold-caps,
    &.mod--bold {
        font-weight: 700;
    }

    &--caps,
    &--bold-caps,
    &.mod--caps {
        text-transform: uppercase;
    }
}
