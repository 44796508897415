.contactperson {
    display: flex;
    flex-direction: row;
    padding: 2rem;

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__image {
        flex: 0 0 120px;
        flex-basis: 120px;
        height: auto;
        margin-left: 2rem;

        img {
            border: 1px solid color('ui-soft');
            border-color: var(--color-ui-soft);
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__name {
        margin-bottom: 0;
    }

    &__contactlist {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: auto;

        &__item {
            margin: 0.5rem 0 0 0;
            padding: 0 !important;
        }
    }

    &__link {
        text-decoration: none;
        color: color('main');
        color: var(--color-main);
        display: flex;
        flex-direction: row;
        align-items: center;

        a,
        a:hover {
            text-decoration: none;
        }

        &:hover {
            color: color('ui-spot');
            color: var(--color-primary);
        }

        .icon {
            margin-right: 1rem;
            width: 24px;
            height: 24px;
        }
    }
}
