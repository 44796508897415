$component: '.typeselection';

#{$component} {
    @include big-margin-bottom;

    &__hl {
        margin-bottom: $baseline * 2;
        text-align: center;
    }

    &__types {
        margin: 0 auto #{$baseline * 2};
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            margin: $grid-width;
            vertical-align: text-top;

            @include mediaquery('md', 'lte') {
                display: block;
                width: calc(50% - 2rem);
                float: left;
            }
        }

        @include mediaquery('md', 'lte') {
            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        &:hover {
            > * {
                opacity: 0.5;
            }
        }
    }

    &__link {
        flex: 0 1 auto;
        max-width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        color: inherit;
        text-decoration: inherit;
        text-transform: inherit;
        transition: 0.3s all;
        word-wrap: break-word;

        &:hover {
            opacity: 1;
        }
    }

    &__counter {
        @extend .text-body;
        margin-bottom: 0.3em;
    }

    &__icon {
        //margin: 0 auto 1rem auto;
        height: 60px;
        //
        //a[href='/fahrzeuge?type=transporter'] &,
        //a[href='/fahrzeuge?type=motorhomes'] & {
        //    width: 88px;
        //}
    }

    &__name {
    }

    &__button {
        text-align: center;
    }
}
