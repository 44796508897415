$component: '.productstream';

#{$component} {
    // @include big-margin-bottom;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    &__hl {
        margin-bottom: $baseline * 2;
        text-align: center;
    }

    &__swiper-wrapper {
        position: relative;
    }

    &__car-cards {
        margin: $grid-width;
        height: calc(100% - #{$grid-width * 4});

        .s-car-card__wrapper,
        .s-car-card__cols {
            height: 100%;
        }

        .s-car-card__cols {
            height: calc(100% - 48px);
		}

        .s-car-card {
            &__facts li div {
                display: inline-block;
            }

            &__energy-efficiency-tag {
                margin-top: 4px;
            }
        }

        .s-car-card__info {
            margin-top: auto;
        }

        .s-car-card__text {
            min-height: 21rem;
        }

        .s-car-card__price-bar {
            @include mediaquery('x3l') {
                flex-wrap: nowrap;
            }
        }
    }
    &__btn-prev,
    &__btn-next {
        position: absolute;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;

        &[aria-disabled='true'] {
            opacity: 0;
            cursor: auto;
        }
    }
    &__btn-prev {
        right: 100%;

        @include mediaquery('lg', 'lte') {
            margin-right: -1.5rem;
        }
    }
    &__btn-next {
        left: 100%;

        @include mediaquery('lg', 'lte') {
            margin-left: -1.5rem;
        }
    }

    // Tiny slider
    .tns-slider {
        position: relative;
        display: block;
        overflow: hidden;
    }
}
