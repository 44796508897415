$component: '.opener';

#{$component} {
  margin-top: -$baseline;
  margin-bottom: 11rem;

  &__wrapper {
    padding: 0;
  }

  &__classic-banner {
    display: inline;
  }

  &__filter-container {
    position: relative;
    color: color('background');
    color: var(--color-background);
    padding: $grid-width;

    @include mediaquery('lg') {
      padding: $grid-width * 4;
    }
    @include mediaquery('xl') {
      display: flex;
      align-items: center;
      padding-right: 310px;
      height: 100%;
      box-sizing: border-box;
    }
  }

  &__ai-search-background-image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 66%);
    pointer-events: none;
  }

  &__ai-search-filter-container {
    position: relative;
    color: color('background');
    color: var(--color-background);
    padding: $grid-width $grid-width 0 $grid-width;

    @include mediaquery('lg') {
      padding: $grid-width * 4 $grid-width * 4 0 $grid-width * 4;
    }
    @include mediaquery('xl') {
      display: flex;
      align-items: center;
      padding-right: 60px;
      height: 100%;
      box-sizing: border-box;
    }
  }

  &__ai-search-content-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;

    @include mediaquery('lg', 'lte') {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__ai-search-content-container-right {
    //width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-left: 35px;
    min-width: 440px;
    max-width: 75%;

    @include mediaquery('lg', 'lte') {
      margin-left: 0;
    }

    @include mediaquery('md', 'lte') {
      min-width: unset;
      max-width: unset;
    }
  }

  &__ai-search-content-container-left {
    padding-bottom: $grid-width;
    min-width: 440px;

    @include mediaquery('lg', 'lte') {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: $grid-width * 4;
    }
    @include mediaquery('md', 'lte') {
      min-width: unset;
    }
  }

  &__ai-search-headline {
    max-width: none;
    margin-top: 15px;

    @include mediaquery('lg', 'lte') {
      text-align: center;
    }
  }

  &__ai-search-example-image {
    display: flex;
    cursor: pointer;

    @include mediaquery('md', 'lte') {
      width: 100%;
    }
  }

  &__headline {
    text-align: left;
    max-width: 65rem;
    margin-bottom: $block-height;

    @include mediaquery('md', 'lte') {
      font-size: 3.8rem;
      line-height: 3.8rem;
      text-transform: none;
    }
  }

  &__filter-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__filter-form {
    position: relative;
    z-index: 1;
  }

  &__filter-text {
    max-width: 73rem;
  }

  &__filter-text-part {
    margin-right: #{$grid-width};
  }

  &__select {
    display: inline-block;
    color: color('background');
    color: var(--color-background);
    border-bottom: solid 1px currentColor;
    margin: 0 #{$grid-width} 0 0;

    .select__select {
      display: inline-block;
      font-size: inherit;
      color: inherit;
      background-color: transparent;
      text-transform: inherit;
      padding-left: 0;
      padding-right: 2.5rem;
      height: 4.6rem;


      &:focus {
        box-shadow: none;
      }

      option,
      optgroup {
        color: #000000;
      }

      &[name='model'] {
        min-width: 24rem;
      }
    }

    .select__icon {
      opacity: 1;
      right: 0;
    }
  }

  &__form-button {
    margin-top: $block-height;

    @include mediaquery('md', 'lte') {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      margin-bottom: -1.5rem;
      display: block;
      width: calc(100% + 3rem);
    }
  }

  &__row {
    flex-direction: column;

    @include mediaquery('xl') {
      flex-direction: row;
    }
  }

  &__cars {
    @include mediaquery('xl') {
      margin-left: -265px;
    }
  }

  &__car-card {
    display: none;
    margin: $grid-width;

    &.move {
      transition: all 0.35s $ease-out-out;
    }

    &:first-of-type {
      display: block;
    }

    @include mediaquery('xl') {
      &:nth-of-type(2) {
        display: block;
      }
    }
  }

  &__new-cell {
    position: relative;
    z-index: 1;
    padding-top: $baseline / 2;
  }

  &__new-container {
    @include mediaquery('xl') {
      margin-left: -#{$grid-width * 6};
    }
  }

  &__new-headline {
    @extend .text-body--bold-caps;

    @include mediaquery('lg', 'lte') {
      margin-left: $grid-width;
      margin-right: $grid-width;
    }
  }

  &__advice {
    padding: #{$baseline / 2} 0;

    @include mediaquery('md', 'lte') {
      display: none;
    }
  }

  &__advice-hl {
    display: inline-block;
  }

  &__advice-links {
    @extend .text-small--caps;
    display: inline-block;
    font-size: 1.8rem;
    margin-left: 1rem;
  }

  &__advice-link {
    color: inherit;
    text-decoration: none;
    margin-right: $grid-width;
  }

  .swiper-slide {
    @include mediaquery('xl') {
      height: auto;
    }
  }

  .s-car-card.mod--slim {
    .s-car-card__wrapper {
      display: flex;
      flex-direction: column;
    }

    .s-car-card__img-wrapper {
      @include mediaquery('xl') {
        flex: 0 0 205px;
        max-width: none;
        flex-shrink: 0;
      }
    }

    .s-car-card__info {
      @include mediaquery('xl') {
        width: auto;
      }
    }
  }

  &__highlight-off {
    border: solid 5px color('ui-highlight');
    margin: -5px;
    opacity: 0.001;
    transition: opacity 0.5s;
    z-index: 999999999;
  }

  &__highlight-on {
    opacity: 1;
  }
}
